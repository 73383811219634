<template>
  <v-container
    :class="`d-flex ${$vuetify.breakpoint.lgAndUp ? 'align-center' : 'align-start pt-16'} container--fluid background`"
  >
    <v-row
      class="d-flex align-center"
    >
      <v-col
        cols="12"
        lg="6"
      >
        <v-container class="d-flex justify-center align-center">
          <v-img
            contain
            src="../assets/logo.png"
            max-width="100px"
          />
          <span class="text-h2 font-weight-black ml-2">Arizona Facility Services</span>
        </v-container>
      </v-col>
      <v-col
        cols="12"
        lg="4"
        class="d-flex justify-center flex-wrap"
        :style="`margin-right: ${$vuetify.breakpoint.lgAndUp ? '15vw' : ''} `"
      >
        <v-col
          cols="12"
          class="d-flex justify-center"
        >
          <v-card
            width="500px"
          >
            <v-progress-circular
              v-if="checkingId"
              indeterminate
              color="grey"
            />
            <v-form
              v-if="validId"
              v-model="isFormValid"
              onsubmit="return false;"
              @submit="submit()"
            >
              <v-card-text>
                <v-container class="d-flex justify-center flex-wrap pb-0">
                  <img
                    :src="require(`../assets/${companyName === 'afs' ? 'logo.png' : `${companyName}.svg`}`)"
                  >
                  <v-card-title
                    class="text-h2 font-weight-bold grey--text justify-center pa-0"
                  >
                    Set Password
                  </v-card-title>
                </v-container>
                <v-sheet
                  width="80%"
                  style="margin-right: 10%; margin-left: 10%"
                >
                  <v-text-field
                    v-model="password"
                    label="Enter password"
                    :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                    :type="showPassword ? 'text' : 'password'"
                    :rules="[rules.required]"
                    @click:append="showPassword = !showPassword"
                  />
                  <v-text-field
                    v-model="repeatPassword"
                    label="Re-enter password"
                    :append-icon="showRepeatPassword ? 'mdi-eye' : 'mdi-eye-off'"
                    :type="showRepeatPassword ? 'text' : 'password'"
                    :rules="[rules.required, rules.matches]"
                    @click:append="showRepeatPassword = !showRepeatPassword"
                  />
                </v-sheet>
              </v-card-text>
              <v-card-actions class="justify-center pb-6">
                <v-btn
                  v-if="!loading"
                  :disabled="!isFormValid"
                  color="green darken-1"
                  text
                  width="80%"
                  height="56px"
                  style="font-size: 14px"
                  type="submit"
                >
                  Submit
                </v-btn>
                <v-container
                  v-else
                  style="width: 80%"
                  class="d-flex justify-center align-center green lighten-3 text-center"
                >
                  <v-progress-circular
                    indeterminate
                    color="green"
                  />
                  <span class="ml-2 green--text text--darken-2">Setting password...</span>
                </v-container>
              </v-card-actions>
            </v-form>
          </v-card>
        </v-col>
        <v-col
          cols="12"
          class="d-flex justify-center"
        >
          <v-alert
            v-if="showStatusMessage"
            type="error"
            width="500px"
            class="mt-2"
          >
            {{ statusMessage }}
          </v-alert>
        </v-col>
        <v-col
          cols="12"
          class="d-flex justify-center"
        >
          <v-btn
            v-if="showStatusMessage"
            color="brown lighten-4"
            light
            @click="backToLogin"
          >
            Return To Login/Reset Password
          </v-btn>
        </v-col>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import registerApi from '@/api/register'
  import store from '@/store'
  import cookie from '@/api/cookie.js'
  import { libraryGetByValue } from '@/assets/companyLibrary'

  export default {
    name: 'SetPassword',
    props: {
      email: String,
    },
    data: () => ({
      title: 'Password Reset',
      password: '',
      repeatPassword: '',
      showPassword: false,
      showRepeatPassword: false,
      loading: false,
      isFormValid: false,

      checkingId: true,
      validId: false,

      showStatusMessage: false,
      statusMessage: '',
      statusMessage500: 'We were unable to complete your request at this time. Please try again later.',
      statusMessage403: "The time frame for this request has expired. Please restart the process for this request, or contact your site's administrator.",

      companyName: '',
    }),
    computed: {
      rules () {
        const rules = {}

        rules.required = (value) => !!value || 'Required.'
        rules.matches = () => this.password === this.repeatPassword || 'Your passwords must match'
        return rules
      },
    },
    created () {
      registerApi
        .validateId(this.$route.query.tenant, this.$route.query.id)
        .then((response) => {
          console.log(response)
          this.companyName = libraryGetByValue(this.$route.query.tenant)
          if (this.companyName === false) throw new Error('Failed to locate tenant.')
          this.validId = true
        })
        .catch((error) => {
          console.log(error)
          if (error.response?.status === 403) {
            this.statusMessage = this.statusMessage403
          } else {
            this.statusMessage = this.statusMessage500
          }
          this.showStatusMessage = true
        })
        .finally(() => {
          this.checkingId = false
        })
    },
    methods: {
      submit () {
        this.statusMessage = false
        this.loading = true

        registerApi
          .updateAuth(this.$route.query.tenant, this.$route.query.id, this.password)
          .then((response) => {
            if (response.status === 200) {
              store.set('user/Role', response.data.userRoles[0].roleId)
              if (response.data.userRoles[0].roleId === 1) {
                store.set('app/addAdmin')
              } else {
                store.set('app/clearItems')
              }
              cookie.createCookie('jwt', response.data.token, 1)
              this.$router.push({ path: '/dashboard/' })
            } else throw new Error()
          })
          .catch((error) => {
            console.log(error)
            if (error.response.status === 403) {
              this.statusMessage = this.statusMessage403
            } else {
              this.statusMessage = this.statusMessage500
            }
            this.showStatusMessage = true
          })
          .finally(() => {
            this.loading = false
          })
      },
      backToLogin () {
        this.$router.push({ path: `/login/${this.companyName}/` })
      },
    },
  }
</script>
<style scoped>
.background {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  max-width: 110vw;
  overflow: hidden;
  background-image: linear-gradient(to right, rgba(245, 246, 252, 0.75), rgba(51, 9, 1, 0.73)),
  url('../assets/register.jpg');
  background-size: cover;
}
@media only screen and (max-width: 1264px) {
  .background {
  background-image: linear-gradient(to bottom, rgba(245, 246, 252, 0.75), rgba(51, 9, 1, 0.73)),
  url('../assets/register.jpg');
  }
}
</style>
